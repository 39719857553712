import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import './App.css';
import DownloadList from './components/DownloadList';
import InputForm from './components/InputForm';
import Sakana from './components/Sakana';
import { getString } from './resources/strings';
import { requestDeviceId } from './services/api';
import Logo from './components/Logo';

const App: React.FC = () => {

    const [refreshTrigger, setRefreshTrigger] = useState(false);

    useEffect(() => {
        // Ensure that a unique device ID is generated for this client
        requestDeviceId()
    }, [])

    return (
        <div className="app">
            <Logo style={{display: 'block', margin: '20px auto', maxHeight: '150px', maxWidth: '95vw'}} />
            <p className="app-description">{getString('appDescription')}</p>
            <InputForm setRefreshTrigger={setRefreshTrigger} />
            <DownloadList refreshTrigger={refreshTrigger} />
            <Toaster position="bottom-left" />
            <Sakana style={{margin: '0 auto'}} />
        </div>
    );
};

export default App;
