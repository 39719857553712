import { CSSProperties } from "react"

interface LogoProps {
    style?: CSSProperties
    className?: string
}

export default function Logo({ style, className }: LogoProps) {
    return (
        <img src="/logo.png" alt="SakanaTube" style={style} className={className} />
    )
}