import { SakanaReact } from "sakana-react";
import { getString } from "../resources/strings";

interface Props {
    style?: React.CSSProperties
}

export default function Sakana({ style }: Props) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', ...style}}>
            <SakanaReact style={style} showControler={false} />
            <span style={{fontWeight: 'bold', userSelect: 'none'}}>{getString('pullMe')}</span>
        </div>
    )
}