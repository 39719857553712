import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { getString } from '../resources/strings';
import { requestDownload } from '../services/api';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Tooltip } from 'react-tooltip';
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';

interface InputFormProps {
    setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>
}

interface FormInput {
    url: string
    startTime: string
    endTime: string
    mediaFormat: 'video' | 'audio'
}

const InputForm: React.FC<InputFormProps> = ({ setRefreshTrigger }) => {

    const [mediaFormat, saveMediaFormat] = useLocalStorage('mediaFormat', 'audio')

    const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm<FormInput>({
        defaultValues: {
            url: '',
            startTime: '',
            endTime: '',
        }
    })

    const validateTimestamp = (timestamp: string) => {
        // Allow blank timestamp
        if(timestamp === '') return true

        const isNotANumber = (part: string) => {
            return isNaN(parseInt(part))
        }

        const inRange = (part: string) => {
            const number = parseInt(part)
            return number >= 0 && number <= 59
        }

        // Ensure that all 
        const parts = timestamp.split(':')
        if(parts.length > 3) {
            return false
        }

        if(parts.length === 1 && isNotANumber(parts[0])) {
            // Only seconds
            return false
        } else if(parts.length === 2 && (!inRange(parts[0]) || !inRange(parts[1]))) {
            // Minutes and seconds
            return false
        } else if(parts.length === 3 && (!inRange(parts[1]) || !inRange(parts[2]))) {
            // Hours, minutes and seconds
            return false
        }
        return true
    }

    const onSubmit: SubmitHandler<FormInput> = async (data) => {
        const { url, startTime, endTime, mediaFormat } = data

        if(url === '') {
            toast.error(getString('invalidUrl'))
            return
        }

        // Validate startTime and endTime to be valid format
        if(!validateTimestamp(startTime)) {
            toast.error(getString('invalidStartTime'))
            return
        }

        if(!validateTimestamp(endTime)) {
            toast.error(getString('invalidEndTime'))
            return
        }

        await requestDownload({
            url,
            media_format: mediaFormat,
            start_time: startTime !== '' ? startTime : undefined,
            end_time: endTime !== '' ? endTime : undefined,
        })
        setRefreshTrigger(val => !val)
        
        // Save choice of media format as same user tends to use for the same purpose each time
        saveMediaFormat(mediaFormat)
        reset()
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="input-form">
            <div className="input-group">
                <label htmlFor="url">URL</label>
                <input
                    type="text"
                    id="url"
                    {...register("url")}
                />
            </div>
            <div className="input-group">
                <label style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <span>{getString('startTime')}</span>
                    <InformationCircleIcon id="timestamp-tooltip" style={{width: '24px'}} />
                    <Tooltip style={{maxWidth: '300px'}} anchorSelect="#timestamp-tooltip" content={getString('timestampTooltip')} />
                </label>
                <input
                    type="text"
                    id="start-time"
                    {...register('startTime')}
                />
            </div>
            <div className="input-group">
                <label htmlFor="end-time">{getString('endTime')}</label>
                <input
                    type="text"
                    id="end-time"
                    {...register('endTime')}
                />
            </div>
            <div style={{marginBottom: '10px'}}>
                <span style={{display: 'block', marginBottom: '5px'}}>{getString('downloadFormat')}</span>
                <div className="radio-holder">
                    <label className="radio-group">
                        <input type="radio" value="audio" defaultChecked={mediaFormat === 'audio'} {...register("mediaFormat")} />
                        <span>{getString('audio')}</span>
                    </label>
                    <label className="radio-group">
                        <input type="radio" value="video" defaultChecked={mediaFormat === 'video'} {...register("mediaFormat")} />
                        <span>{getString('video')}</span>
                    </label>
                </div>
            </div>
            <button disabled={isSubmitting} type="submit" className="upload-button">{getString('startDownload')}</button>
        </form>
    );
};

export default InputForm;
