import React from 'react';
import { DownloadInfo } from '../services/api';
import { getString } from '../resources/strings';
import ClipboardCopy from './ClipboardCopy';
import ArrowDownTrayIcon from '@heroicons/react/24/outline/ArrowDownTrayIcon'

interface DownloadItemProps {
    item: DownloadInfo;
}

const DownloadItem: React.FC<DownloadItemProps> = ({ item }) => {
    const isComplete = item.progress === 100;
    
    return (
        <div className="download-item">
            <div>{item.title}</div>
            <div>{item.duration}</div>
            <div>{getString(item.media_format)}</div>
            <progress value={item.progress} max="100" />
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                <button disabled={!isComplete} onClick={() => window.location.href = item.url}>
                    <ArrowDownTrayIcon style={{width: '24px'}} />
                </button>
                <ClipboardCopy disabled={!isComplete} content={`${window.location.origin}${item.url}`} />
            </div>
        </div>
    );
};

export default DownloadItem;
