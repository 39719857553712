export const strings: Record<string, Record<string, string>> = {
    'en': {
        appName: 'SakanaTube',
        appDescription: 'Download and trim Youtube videos',
        url: 'URL',
        startTime: 'Start Time',
        endTime: 'End Time',
        downloadFormat: 'Download Format',
        audio: 'Audio',
        video: 'Video',
        startDownload: 'Start Download',
        download: 'Download',
        invalidUrl: 'Invalid URL',
        invalidStartTime: 'Invalid start time',
        invalidEndTime: 'Invalid end time',
        timestampTooltip: 'HH:MM:SS, MM:SS, SS (will be converted to HH:MM:SS)',
        pullMe: 'Pull me!'
    },
    'ja': {
        appName: 'SakanaTube',
        appDescription: 'Youtubeの動画をダウンロードして切り取る',
        url: 'URL',
        startTime: '開始時点',
        endTime: '終了時点',
        downloadFormat: '形式',
        audio: '音声',
        video: '動画',
        startDownload: 'ダウンロード開始',
        download: 'ダウンロード',
        invalidUrl: 'URLを確認してください',
        invalidStartTime: '開始時点を確認してください',
        invalidEndTime: '終了時点を確認してください',
        timestampTooltip: 'HH:MM:SS, MM:SS, SS (秒だけ入力すれば自動的にHH:MM:SSに直されます)',
        pullMe: '私を引っ張って！'
    }
}

const language = (() => {
    const browserLanguage = navigator.language
    if(Object.keys(strings).includes(browserLanguage))
        return browserLanguage
    else
        return 'en'
})()

export const getString = (stringName: string) => {
    return strings[language][stringName]
}