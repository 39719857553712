import DocumentDuplicateIcon from '@heroicons/react/24/outline/DocumentDuplicateIcon'
import toast from 'react-hot-toast'

interface Props {
    content?: string
    disabled?: boolean
}

export default function ClipboardCopy({ content, disabled=false }: Props) {

    const copy = () => {
        if(content) {
            navigator.clipboard.writeText(content)
            toast.success(`Copied: ${content}`, {
                duration: 1200,
                style: {
                    maxWidth: '300px',
                    wordBreak: 'break-all',
                }
            })
        }
    }

    return (
        <button disabled={disabled} onClick={() => copy()}>
            <DocumentDuplicateIcon style={{width: '24px'}} />
        </button>
    )
}