export interface DownloadRequest {
    url: string;
    start_time?: string;
    end_time?: string;
    media_format: string
}

export interface DownloadInfo {
    url: string;
    title: string;
    duration: string;
    media_format: string;
    progress: number;
}

export const requestDownload = async (data: DownloadRequest): Promise<void> => {
    const response = await fetch('/download', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error('Failed to upload video');
    }
};

export const getDownloads = async (): Promise<DownloadInfo[]> => {
    const response = await fetch('/download');
    if (!response.ok) {
        throw new Error('Failed to fetch downloads');
    }
    return await response.json();
};

export const requestDeviceId = async(): Promise<void> => {
    const response = await fetch('/deviceid')
    if(!response.ok) {
        throw new Error('Failed to get device ID')
    }
    return await response.json()
}