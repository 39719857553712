import React, { useEffect, useState, useRef } from 'react';
import { getDownloads, DownloadInfo } from '../services/api';
import DownloadItem from './DownloadItem';

interface DownloadListProps {
    refreshTrigger: boolean;
}

// Test items for UI dev
/*
[{
    url: "/downloads/3",
    progress: 100,
    title: "test",
    duration: "01:20:00",
    media_format: 'audio'
}, {
    url: "/downloads/5",
    progress: 30,
    title: "test test test test test test test test test test test 2",
    duration: "00:50:20",
    media_format: 'video'
}]
*/

const DownloadList: React.FC<DownloadListProps> = ({ refreshTrigger }) => {
    const [downloads, setDownloads] = useState<DownloadInfo[]>([]);
    const intervalRef = useRef<number | null>(null);

    const fetchDownloads = async () => {
        try {
            const data = await getDownloads();
            setDownloads(data);
        } catch (error) {
            console.error('Failed to fetch downloads:', error);
        }
    };

    useEffect(() => {
        fetchDownloads();
        if (intervalRef.current === null) {
            intervalRef.current = window.setInterval(fetchDownloads, 2000);
        }

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [refreshTrigger]);

    return (
        <div className="download-list">
            {downloads.map((item) => (
                <DownloadItem key={item.url} item={item} />
            ))}
        </div>
    );
};

export default DownloadList;
